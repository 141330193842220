import { formatWholeDollarAmount } from "../../helpers";
import InfoIcon from "../DesignSystem/atoms/InfoIcon/InfoIcon";
import Typography from "../DesignSystem/atoms/Typography/Typography";
import './EstimatedDistribuitionCard.scss'
import Tooltip from '@mui/material/Tooltip';
import { IconButton } from "@mui/material";

export interface BodyMessage {
  message?: string
  title?: string
}

export interface DistribuitionCardProps {
  totalValue: string
  debts: string
  alixFee: string
  estimatedDistribution: string
  distributionInfo?: BodyMessage | null | undefined
  feeCalculationInfo?: BodyMessage | null | undefined
}

export default function EstimatedDistribuitionCard({
  totalValue, debts, alixFee, estimatedDistribution, distributionInfo, feeCalculationInfo,
}: DistribuitionCardProps) {

  return (
    <>
      <div className="estimateCardContainer">
        <div className="internalContainer">
          <Typography className="estimatedValue" variant="P3" font="UI1" >
            <div className="colorEstimatedName">Estimated distribuition</div></Typography>
          <Tooltip enterTouchDelay={0} title={
            <>
              <h4 className="fontWEstimateTitle">{distributionInfo?.title}</h4>
              <p>{distributionInfo?.message}</p>
            </>
          } placement="top-start">
            <IconButton
              size="small"
              aria-haspopup="true"
            >
              <InfoIcon width={18} height={18} />
            </IconButton>

          </Tooltip>

        </div>

        <Typography variant="H3" font="UI1" fontWeight="semibold">{formatWholeDollarAmount(parseFloat(estimatedDistribution))}</Typography>
        <hr />

        <div className="divider">
          <div className="container">
            <Typography variant="L3" font="UI1" >Total asset value</Typography>
            <Typography variant="L3" font="UI1" >Debts</Typography>
            <div className="dividerInternalGap">
              <Typography variant="L3" font="UI1" >Alix fee</Typography>
              <Tooltip enterTouchDelay={0} title={
                <>
                  <h4 className="fontWEstimateTitle">{feeCalculationInfo?.title}</h4>
                  <p>{feeCalculationInfo?.message}</p>
                </>
              } placement="top-start">
                <IconButton
                  size="small"
                  aria-haspopup="true"
                >
                  <InfoIcon width={18} height={18} />
                </IconButton>
              </Tooltip>
            </div>
            <Typography variant="L3" font="UI1" fontWeight="semibold">Estimated distribuition</Typography>
          </div>
          <div className="container">
            <Typography variant="L3" font="UI1" >{formatWholeDollarAmount(parseFloat(totalValue ?? 0))}</Typography>
            <Typography variant="L3" font="UI1" >{`$(${formatWholeDollarAmount(parseFloat(debts ?? 0)).replace("$", "")})`}</Typography>
            <Typography variant="L3" font="UI1" >{`$(${formatWholeDollarAmount(parseFloat(alixFee ?? 0)).replace("$", "")})`}</Typography>
            <Typography variant="L3" font="UI1" fontWeight="semibold">{formatWholeDollarAmount(parseFloat(estimatedDistribution))}</Typography>
          </div>
        </div>
        <hr />
      </div>
    </>
  )
}