import { useLocation } from 'react-router-dom';


const useGetParamValue = (
  paramName: string,
) => {
  const query = new URLSearchParams(useLocation().search);

  return query.get(paramName);
}

export default useGetParamValue;