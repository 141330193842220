import { FC, useContext, useEffect } from 'react'
import './Desktop.scss'
import { UserLayout } from '../../components/Layouts'
import { useParams } from 'react-router-dom'
import EstateComponentSummary from '../../components/DesignSystem/molecules/EstateComponentSummary/EstateComponentSummary'
import ComponentDetailCardList from '../../components/DesignSystem/molecules/ComponentDetailCardList/ComponentDetailCardList'
import Typography from '../../components/DesignSystem/atoms/Typography/Typography';
import { useScreenWidth } from '../../helpers'
import { AccountContext } from '../../providers/Authentication'
import { useRoleNavigate } from '../../hooks/useRoleNavigate'

interface EstateComponentDetailsProps {
  testId?: string
}

const EstateComponentDetails: FC<EstateComponentDetailsProps> = ({testId}) => {
  const {estateComponentId} = useParams();
  const screenWidth = useScreenWidth();
  const { showAdminTools } = useContext(AccountContext)
  const navigate = useRoleNavigate()

  useEffect(() => {
    if(showAdminTools) {
      navigate(`/estateComponentDetails/${estateComponentId}`)
    }

  }, [showAdminTools])

  return (
    <UserLayout
      includeSubHeader={false}
      includeFooter={false}
      includeCareTeamCard={false}
      fixedHeader={screenWidth < 800}
    >
      <div id='estateComponentDetailsDesktop' data-testid={testId} className='estateComponentDetailsContainer'>
        <div className="summaryHeadingContainer">
          <EstateComponentSummary estateComponentId={estateComponentId || ''} />
          <div className="listHeading">
            <Typography variant="H6" font='UI1'>{`What's happening`}</Typography>
          </div>
        </div>

        <div className='cardListContainer'>
          <ComponentDetailCardList estateComponentId={estateComponentId || ''}/>
        </div>
      </div>
    </UserLayout>
  )
}

export default EstateComponentDetails
