import { FC } from 'react'
import './NumberCircle.scss'
import { Typography } from '@mui/material'

interface NumberCircleProps {
  testId?: string,
  number: number
}

const NumberCircle: FC<NumberCircleProps> = ({
  testId,
  number = 0,
}) => {
  return (
    <div data-testid={testId} className='numberCircle'>
      <Typography variant='body1' className='light'>
        {number}
      </Typography>
    </div>
  )
}

export default NumberCircle