import { FC } from 'react'

import { DesktopLayout } from '../../../components/Layouts'
import DocumentsList from '../DocumentsList.tsx/DocumentsList'

import styles from './Desktop.module.scss'
import Typography from '../../../components/DesignSystem/atoms/Typography/Typography'

const Desktop: FC = () => {
  return (
    <DesktopLayout
      includeSubHeader={false}
    >
      <div className={styles.documentsHeaderContainer}>
        <Typography variant='H3' font="UI2" className={styles.documentsHeader}>Document Vault</Typography>
      </div>
      <DocumentsList />
    </DesktopLayout>
  )
}

export default Desktop