import { CheckCircle, CheckCircleOutline } from '@mui/icons-material';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import ErrorIcon from '@mui/icons-material/Error';
import CancelIcon from '@mui/icons-material/Cancel';
import { ActionComponent } from '../providers/ActionProvider';
import { Deceased } from '../providers/EstateProvider';
import { ActionCardStatus, ComponentStatus, RouteElementType } from '../types/graphql';
import { EstateComponent } from '../views/EstateView';
import pluralize from 'pluralize';
import { isNil } from 'lodash';
const {singular} = pluralize;

// Action Card Helpers

const convertActionCardStatusToHumanReadable = (actionCardStatus: string) => {
  const capitalizedOption = actionCardStatus.toLowerCase()
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
  return capitalizedOption
};

const formatActionName = (component: ActionComponent | null | undefined) => {
  if (component) {
    const name = component.name
    const descriptor = component.descriptor
    const accountNumber = component.accountNumber?.slice(-4)

    let actionName = `${name}`
    if (descriptor) actionName += ` - ${descriptor}`
    if (accountNumber) actionName += ` - ${accountNumber}`

    return actionName
  }
  return ''
};

const formatEstateSelect = (estate: any) => {
  const executor = estate?.users?.length > 0 ? [estate.users[0].user.firstName, estate.users[0].user.lastName].join(' ') : `[No Executor Assigned]`;
  return [
    `Estate of ${estate?.deceased ? estate?.deceased?.firstName + ' ' + estate?.deceased?.lastName : null}`,
    executor,
  ].filter(item => Boolean(item)).join(' - ')
}

const getActionCardStatusIconAndMessages = (statusType: ActionCardStatus) => {
  let statusIcon: JSX.Element | null;
  let statusMsgShort: string;
  let statusMsgLong: string;
  let statusClass: string;

  switch (statusType) {
  case ActionCardStatus.New:
    statusIcon = <ErrorIcon className='isErrorIcon' />;
    statusMsgShort = "Action Required";
    statusMsgLong = "Requires Attention";
    statusClass = "attention";
    break;
  case ActionCardStatus.Completed:
    statusIcon = <CheckCircleOutline className="isCompletedIcon" />;
    statusMsgShort = "Completed";
    statusMsgLong = "Is completed with Alix";
    statusClass = "complete";
    break;
  case ActionCardStatus.InProgress:
    statusIcon = <RefreshOutlinedIcon className="isProgressIcon" />;
    statusMsgShort = "In progress";
    statusMsgLong = "In progress with Alix";
    statusClass = "inProgress";
    break;
  case ActionCardStatus.Canceled:
    statusIcon = <CancelIcon className="isCanceledIcon" />;
    statusMsgShort = "Canceled";
    statusMsgLong = "Canceled";
    statusClass = "canceled";
    break;
  default:
    statusIcon = null;
    statusMsgShort = "";
    statusMsgLong = "";
    statusClass = "";
    break;
  }

  return ({
    statusIcon,
    statusMsgShort,
    statusMsgLong,
    statusClass,
  });
};

// Estate Component Helpers

const getEstateComponentStatusIconAndMessages = (statusType: ComponentStatus) => {
  let statusIcon: JSX.Element | null;
  let statusMsgShort: string;
  let statusMsgLong: string;
  let statusClass: string;

  switch (statusType) {
  case ComponentStatus.Pending:
    statusIcon = <RefreshOutlinedIcon className="isInProgressIcon" />;
    statusMsgShort = "In progress";
    statusMsgLong = "In progress with Alix";
    statusClass = "inProgress";
    break;
  case ComponentStatus.Completed:
    statusIcon = <CheckCircleOutline className="isCompletedIcon" />;
    statusMsgShort = "Completed";
    statusMsgLong = "Is completed with Alix";
    statusClass = "complete";
    break;
  case ComponentStatus.InProgress:
    statusIcon = <RefreshOutlinedIcon className="isInProgressIcon" />;
    statusMsgShort = "In progress";
    statusMsgLong = "In progress with Alix";
    statusClass = "inProgress";
    break;
  case ComponentStatus.Blocked:
    statusIcon = <ErrorIcon className='isBlockedIcon' />;
    statusMsgShort = "Blocked";
    statusMsgLong = "Requires Attention";
    statusClass = "blocked";
    break;
  default:
    statusIcon = null;
    statusMsgShort = "";
    statusMsgLong = "";
    statusClass = "";
    break;
  }

  return ({
    statusIcon,
    statusMsgShort,
    statusMsgLong,
    statusClass,
  });
}

const getComponentDescriptorMsg = (component: EstateComponent) => {
  const {
    name, descriptor, accountNumber,
    address, city, state, zip,
    subType,
  } = component;

  switch (subType) {
  case 'RealEstate':
    // return address || descriptor + " " + [city,state,zip].filter(el => !isNil(el)).join(", ");
    return address || [city,state,zip].filter(el => !isNil(el)).join(", ");
  case 'BankAccount':
    if(accountNumber) return [name,descriptor,accountNumber].filter(el => !isNil(el)).join(" - ");
    if(descriptor) return [name,descriptor].filter(el => !isNil(el)).join(" - ");
    // if(accountNumber) return [name,accountNumber].filter(el => !isNil(el)).join(" - ");
    return name;
  case 'LifeInsurance':
  default:
    // return `${name}, ${descriptor}`;
    return `${name}`;
  }
};

// General Purpose Functions
const buildRouteFromBEUrl = (url: string) => {
  let includeQueryParam = false;
  const queryParams: string[] = [];
  const routeElementTypeValues = Object.values(RouteElementType);

  const cleanedUrl = '/' + url.split('/')
    .map((urlPart, i, arr) => {

      switch(true) {
      case urlPart === RouteElementType.ActionCard:
        // Right now action cards are found on the Home page
        urlPart = 'actionDetails'
        break;
      case urlPart === RouteElementType.EstateComponent:
        // We have a dedicated view for estate components
        urlPart = 'estateComponentDetails'
        break;
      case urlPart === RouteElementType.EstateContent:
        // If the array doesn't have a next item, or if the next item is one of the RouteElementType values, then we need to exclude this part of the url and break, as the EstateContent is accessed via a queryParam
        if (!arr[i+1] || routeElementTypeValues.includes(arr[i+1] as RouteElementType)) {
          urlPart = '';
          break;
        }

        // the estate content id is accessed as a query param so we need to adjust how the final url is built to accommodate the query param
        includeQueryParam = true;
        queryParams.push(`contentId=id-${arr[i+1]}`)

        // remove the next item of the array since it's already been used
        arr.splice(i+1, 1);

        // remove the current item of the array since it should not be used in the url
        urlPart = '';
        break;
      }

      return urlPart;
    })
    .filter(Boolean)
    .join('/');

  if (includeQueryParam) return `${cleanedUrl}?${queryParams.join('&')}`;
  return cleanedUrl;
}

const classNames = (...classes: (string | boolean | undefined | null)[]) => {
  return classes.filter(Boolean).join(' ');
}

const capitalizeFirstLetter = (word: string) => {
  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
};

const convertTimestampToHumanReadable1 = (timestamp: string): string => {
  //10am on March 22th

  const date = new Date(timestamp);

  const month = date.toLocaleString("default", {month: "long"});
  const day = date.getDate();
  const hour = date.getHours();

  const formattedHour = hour > 12 ? hour - 12 : hour;
  const amPm = hour >= 12 ? "pm" : "am";

  const convertedString = `${formattedHour}${amPm} on ${month} ${day}th`;

  return convertedString;
};

const convertTimestampToHumanReadable2 = (timestamp: string): string => {
  // March 22, 2024 or Today

  const date = new Date(timestamp);
  const today = new Date();

  // Check if the date is today
  if (date.getUTCFullYear() === today.getUTCFullYear() &&
      date.getUTCMonth() === today.getUTCMonth() &&
      date.getUTCDate() === today.getUTCDate()) {
    return "Today";
  }

  const month = date.toLocaleString("default", {
    month: "long",
    timeZone: "UTC",
  });
  const day = date.getUTCDate();
  const year = date.getUTCFullYear();

  const convertedString = `${month} ${day}, ${year}`;
  return convertedString;
};

const convertTimestampToHumanReadable3 = (timestamp: string) => {
  // 03-22-2024
  const date = new Date(timestamp);

  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const day = String(date.getUTCDate()).padStart(2, '0');
  const year = String(date.getUTCFullYear());

  const convertedString = `${month}-${day}-${year}`;
  return convertedString;
};

const deceasedFullName = (deceased: Deceased | undefined | null) => {
  if (deceased) {
    return `${deceased?.firstName} ${deceased?.lastName}`
  }
};

const formatDollarAmount = (dollarAmount: number) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });
  return formatter.format(dollarAmount).replace('.00', '');
};

const formatWholeDollarAmount = (dollarAmount: number) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
  });
  return formatter.format(dollarAmount);
}

const pascalToNormalCase = (pascalCaseString: string) => {
  let toReturn = '';

  switch (pascalCaseString) {
  case 'AssetOfValue':
    toReturn = 'Assets of Value';
    break;
  case 'BankAccount':
    toReturn = 'Bank Accounts';
    break;
  case 'Benefit':
    toReturn = 'Benefits';
    break;
  case 'RealEstate':
    toReturn = 'Real Estate';
    break;
  case 'LifeInsurance':
    toReturn = 'Life Insurance';
    break;
  case 'BillToCancel':
    toReturn = 'Bills to cancel';
    break;
  case 'Debt':
    toReturn = 'Debts';
    break;
  case 'Discovery':
    toReturn = 'Discovery';
    break;
  case 'Distribution':
    toReturn = 'Distribution';
    break;
  case 'Investment':
    toReturn = 'Investments';
    break;
  case 'PersonalProperty':
    toReturn = 'Personal Property';
    break;
  case 'Probate':
    toReturn = 'Probate';
    break;
  case 'Tax':
    toReturn = 'Taxes';
    break;
  case 'UnclaimedProperty':
    toReturn = 'Unclaimed Property';
    break;
  case 'Vehicle':
    toReturn = 'Vehicles';
    break;
  case 'Retirement':
    toReturn = 'Retirement';
    break;
  default: // Take your best guess if no case exists
    toReturn = pluralize(pascalCaseString.replace(/([A-Z])/g, ' $1').trim());
    break;
  }

  return toReturn;
};

const makeNamePossessive = (name: string): string => {
  if (!name) {
    return '';
  }
  const lastChar = name[name.length - 1].toLowerCase();
  if (lastChar === 's') {
    return `${name}'`;
  }
  return `${name}'s`;
}

function toCamelCase(str: string) {
  return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function(match, index) {
    if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
    return index === 0 ? match.toLowerCase() : match.toUpperCase();
  });
}

const truncate = (name: string, maxLength: number = 15) => {
  if (name.length <= maxLength) return name;

  const start = name.slice(0, maxLength);
  return `${start}...`;
};


export {
  buildRouteFromBEUrl,
  capitalizeFirstLetter,
  classNames,
  convertActionCardStatusToHumanReadable,
  convertTimestampToHumanReadable1,
  convertTimestampToHumanReadable2,
  convertTimestampToHumanReadable3,
  deceasedFullName,
  formatActionName,
  formatDollarAmount,
  formatWholeDollarAmount,
  formatEstateSelect,
  getActionCardStatusIconAndMessages,
  getComponentDescriptorMsg,
  getEstateComponentStatusIconAndMessages,
  pascalToNormalCase,
  makeNamePossessive,
  toCamelCase,
  truncate,
};