import { useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { AccountContext } from '../providers/Authentication';

export function useRoleNavigate() {
  const navigate = useNavigate();
  const { hasAdminAuthorization } = useContext(AccountContext);

  const navigateRole = useMemo(() => {
    return (path: string, options?: { replace: boolean }) => {
      const navigationPath = hasAdminAuthorization ? '/manage' + path : path;
      if (options?.replace) {
        navigate(navigationPath, { replace: true });
      } else {
        navigate(navigationPath);
      }
    };
  }, [navigate, hasAdminAuthorization]);

  return navigateRole;
}