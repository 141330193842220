import { FC, useContext, useEffect, useState } from 'react'
import './index.scss'
import { FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material'
import { UserEstateRole, useGetSimpleEstateListQuery } from '../../../types/graphql';
import { EstateContext } from '../../../providers/EstateProvider';
import { useRoleNavigate } from "../../../hooks/useRoleNavigate";
import { formatEstateSelect } from '../../../helpers/functions';

interface SelectAnEstateProps {
  testId?: string
}

type User = { id: number, firstName?: string | null, lastName?: string | null }
type UserArray = Array<{ __typename?: 'UsersOnEstates', user: User }>

const SelectAnEstate: FC<SelectAnEstateProps> = ({testId}) => {
  const {
    selectedEstateId, setSelectedEstateId, setRefetchEstatesList,
  } = useContext(EstateContext);
  const {
    data: estatesData, loading, refetch: refetchEstates, 
  } = useGetSimpleEstateListQuery({variables: {where: {userRole: {equals: UserEstateRole.Executor}}}});
  const navigate = useRoleNavigate()

  useEffect(() => {
    if (!selectedEstateId) navigate('/adminLanding')
  }, [])

  useEffect(() => {
    setRefetchEstatesList(() => refetchEstates)
  }, [refetchEstates])

  const handleChange = (e: any) => {
    if (e.target.value !== '') {
      setSelectedEstateId(`${e.target.value}`)
      if (window.location.pathname.includes('adminLanding')) navigate('/home')
    } else {
      navigate('/adminLanding')
    }
  }

  if (loading) return (<Typography>loading...</Typography>)
  return (
    <div data-testid={testId} className='selectAnEstateContainer'>
      <FormControl fullWidth className='selectEstateFormControl'>
        <InputLabel id="demo-simple-select-label">Estate to Manage</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedEstateId || ''}
          label="Estate to Manage"
          name="selectEstate"
          displayEmpty
          onChange={(e) => handleChange(e)}
        >
          {estatesData && estatesData?.estates?.length > 0 && estatesData.estates.map((estate) => {
            const displayValue = formatEstateSelect(estate)
            return (
              <MenuItem
                key={`selectedEstate-${estate.id}`}
                value={estate.id}
              >
                {displayValue}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  )
}

export default SelectAnEstate