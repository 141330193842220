import { FC, useContext } from "react";
import { Container, List, ListItem, Typography } from "@mui/material";

import './Manual.scss';
import ActionButton from "../../../components/DesignSystem/atoms/ActionButton/ActionButton";
import LinkButton from "../../../components/DesignSystem/atoms/LinkButton/LinkButton";
import { Launch } from "@mui/icons-material";
import { ActionContext } from "../../../providers/ActionProvider";
import { convertTimestampToHumanReadable1 } from "../../../helpers/functions";
import CalendarButton from "../../../components/CalendarButton/CalendarButton";
import { ActionCardStatus, useStartActionCardMutation } from "../../../types/graphql";
import { EstateContext } from "../../../providers/EstateProvider";
import { LayoutContext } from "../../../providers/LayoutProvider";
import DocumentUpload from "../DocumentUpload/DocumentUpload";
import DocumentDownload from "../DocumentDownload/DocumentDownload";
interface ManualProps {
  testId?: string
  currentAction: any
}

const Manual: FC<ManualProps> = ({testId, currentAction}) => {

  return (
    <>

      {/* {(currentAction?.calendarIcs || currentAction?.calendarLink) && 
        <CalendarButton 
          variant="outline"
          className="addToCalendarButton"
          fileContent={currentAction?.calendarIcs || undefined}
          fileName={'Calendar-Event'}
          calendarLink={currentAction?.calendarLink || undefined}
        >
          <div className="actionButtonText">
            <Launch />
            <Typography variant="body1">
              Add this event to my calendar
            </Typography>
          </div>
        </CalendarButton>
      } */}

      { currentAction?.mapUrl &&
      <div className="linkButtonContainer">
        <LinkButton 
          variant="solid"
          ariaLabel="link to map"
          className="downloadLinkButton dark"
          handleClick={() => console.log('link pressed')}
          href={currentAction?.mapUrl || ''}
          newTab
        >
          <div className="actionButtonText">
            <Launch />
            <Typography variant="body1" className="light">
              Link to Map
            </Typography>
          </div>
        </LinkButton>
      </div>
      }

      { (currentAction?.appointmentTime || currentAction?.appointmentLocation ) &&
        <>
          <Typography variant="body1" className="actionDetailsBodyHeader">
            {"Appointment Details:"}
          </Typography>

          {/* { currentAction.appointmentTime && 
              <ListItem sx={{display: 'list-item'}}>
                <Typography variant="body1" className="manualListText">
                  <span>{convertTimestampToHumanReadable1(currentAction?.appointmentTime)}</span>
                </Typography>
              </ListItem>
            } */}

          {currentAction?.appointmentLocation &&
            <Typography 
              variant="body1" 
              className="appointmentDetailsBodyText"
              dangerouslySetInnerHTML={{__html: currentAction?.appointmentLocation} || ''}
            />
          }
          <div className="actionDetailsDivider"></div>
        </>
      }

      { currentAction?.documentDownloadUrl &&
        <DocumentDownload currentAction={currentAction} />
      }
    </>
  );
};

export default Manual;

