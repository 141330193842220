import { FC, useContext, useEffect, useState } from 'react'
import './FolderListItem.scss'
import { CircularProgress, Typography } from '@mui/material'
import ActionButton from '../../../components/DesignSystem/atoms/ActionButton/ActionButton'
import {ReactComponent as ChevronDownIcon} from '../../../assets/icons/navigation/chevron-down.svg'
import {ReactComponent as DocumentGroupIcon} from '../../../assets/icons/fileTypes/documents-group.svg'
import { ReactComponent as DeleteIcon } from '../../../assets/icons/fileTypes/trash-can.svg'
import { EstateDocument, useDeleteDocumentMutation, useGetUserEstateIdQuery, useMoveDocumentsMutation, useTrackSectionOpenedMutation } from '../../../types/graphql'
import { useScreenWidth } from '../../../helpers'
import { DocumentContext } from '../../../providers/DocumentProvider'
import FilesList from '../../../components/File/FilesList/FilesList'
import { EstateContext } from '../../../providers/EstateProvider'
import { AccountContext } from '../../../providers/Authentication'
import FilePickerInput from '../../../components/File/FilePickerInput/FilePickerInput'


interface FolderListItemProps {
  testId?: string,
  item: EstateDocument,
  handleClick: (id: string)=> void,
  expanded: boolean,
}

const FolderListItem: FC<FolderListItemProps> = ({
  testId, item, handleClick, expanded,
}) => {
  const {
    setDirectoryPath, setFilePath, folderRefetch, directoryPath, refetchDocumentsList,
  } = useContext(DocumentContext)
  const screenWidth = useScreenWidth()
  const [trackSectionOpenedMutation] = useTrackSectionOpenedMutation();
  const { showAdminTools } = useContext(AccountContext);
  const { selectedEstateId } = useContext(EstateContext);
  const { data:estateIdData } = useGetUserEstateIdQuery({ variables: { estateId: selectedEstateId } });

  const sectionLabel = 'Folder click to view files';
  const [deleting, setDeleting] = useState(false);

  const [deleteDocumentMutation] = useDeleteDocumentMutation({
    variables: {id: item?.id|| ''},
    onCompleted: () => {
      refetchDocumentsList()
      folderRefetch && folderRefetch[directoryPath] && folderRefetch[directoryPath]()
      setDeleting(false)
    },
    onError: (error) => {
      console.error(`Error deleting document: ${item.name}`, error)
      alert(`Error deleting ${item.name}: ${error}`)
      setDeleting(false)
    },
  });

  useEffect(() => {
    if (expanded) {
      setDirectoryPath(item.name);
    }
  }, [expanded, item.name]);

  const clickHandler = async () => {
    handleClick(item.id);

    const estateId = estateIdData?.getUserEstate?.id;

    try {
      if (estateId) {
        await trackSectionOpenedMutation({
          variables: {
            estateId,
            section: sectionLabel,
          },
        });
  
        if (process.env.NODE_ENV === 'development') console.log(`Tracking "${sectionLabel}" Section ${expanded ? 'Open' : 'Close'} on ${window.location.pathname}`);
      }
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <li 
      data-testid={testId} 
      className={`folderListItemContainer`}
    >
      <ActionButton 
        ariaLabel={sectionLabel}
        handleClick={clickHandler}
        variant='solid'
        className={'folderHeader'}
      >
        <div className='folderHeaderContainer'>
          <DocumentGroupIcon className='folderIcon'/>
          <Typography variant='body1'>      
            {item.name}
          </Typography>
        </div>
        <div className="endCap">
          <ChevronDownIcon className={`folderChevronDownIcon ${expanded && 'expanded'}`}/>

          {showAdminTools && <div className='uploadFileContainer'>
            <div 
              className='uploadFileContainer' 
              onClick={(event) => {
                event.stopPropagation();
              }}
            >
              <FilePickerInput destinationFolder={`${item.name}`} expanded={expanded} handleExpand={clickHandler} altText={"Upload"}/>
            </div>
          </div>}
          <div>
            {showAdminTools && (deleting?
              <div className={`fileProgressCircle fileProgressBackground`}>
                <CircularProgress size={22}/>
              </div>
              :
              <ActionButton 
                ariaLabel='Delete Folder'
                variant='solid'
                className={`endCap transparent`}
                handleClick={(event) => {
                  event?.stopPropagation()
                  setDeleting(true)
                  deleteDocumentMutation()
                }}
              // tabIndex={tabIndex}
              >
                <DeleteIcon className='deleteIcon'/>
              </ActionButton>)
            }
          </div>
        </div>
      </ActionButton>
      <FilesList tabIndex={expanded ? 0 : -1} directory={item.name} type={showAdminTools ? 'options' : 'download'} expanded={expanded} className={"folderFilesDropdown"}/>
    </li>
  )
}

export default FolderListItem