import { FC, useContext } from 'react'

import { MobileLayout } from '../../../components/Layouts'
import DocumentsList from '../DocumentsList.tsx/DocumentsList'
import Typography from '../../../components/DesignSystem/atoms/Typography/Typography'
import styles from './Mobile.module.scss'

const Mobile: FC = () => {
  return (
    <MobileLayout
      includeSubHeader={false}
      altTitleText='Documents'
      includeCareTeamCard={false}
      includeFooter={false}
    >
      <div className={styles.documentsHeaderContainer}>
        <Typography variant='H3' font="UI2" className={styles.documentsHeader}>Document Vault</Typography>
      </div>
      <DocumentsList />
    </MobileLayout>
  )
}

export default Mobile