import { FC, useContext, useEffect } from 'react'
import './Mobile.scss'
import { MobileLayout } from '../../components/Layouts'
import { useParams } from 'react-router-dom'
import EstateComponentSummary from '../../components/DesignSystem/molecules/EstateComponentSummary/EstateComponentSummary'
import ComponentDetailCardList from '../../components/DesignSystem/molecules/ComponentDetailCardList/ComponentDetailCardList'
import Typography from '../../components/DesignSystem/atoms/Typography/Typography';
import { useScreenWidth } from '../../helpers'
import { AccountContext } from '../../providers/Authentication'
import { useRoleNavigate } from '../../hooks/useRoleNavigate'

interface EstateComponentDetailsProps {
  testId?: string
}

const MobileView: FC<EstateComponentDetailsProps> = ({testId}) => {
  const {estateComponentId} = useParams();
  const screenWidth = useScreenWidth();
  const { showAdminTools } = useContext(AccountContext)
  const navigate = useRoleNavigate()

  useEffect(() => {
    if(showAdminTools) {
      navigate(`/estateComponentDetails/${estateComponentId}`)
    }

  }, [showAdminTools])

  return (
    <MobileLayout fixedHeader includeBackNavHeader includeSubHeader={false} headerText='Details'>
      <div id="estateComponentDetailsMobile" data-testid={testId} className='estateComponentDetailsContainer'>
        <div className="summaryHeadingContainer">
          <EstateComponentSummary estateComponentId={estateComponentId || ''} />
          <div className="listHeading">
            <Typography variant="H6" font='UI1'>{`What's happening`}</Typography>
          </div>
        </div>

        <div className='cardListContainer'>
          <ComponentDetailCardList estateComponentId={estateComponentId || ''}/>
        </div>
      </div>
    </MobileLayout>
  )
}

export default MobileView
