import { FC, useContext, useState } from 'react'
import './index.scss';
import formData from './formData.json'

import * as Yup from 'yup';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import {useCreateFolderMutation, CreateFolderInput } from '../../../../types/graphql';
import { ApolloError } from '@apollo/client';
import { LayoutContext } from '../../../../providers/LayoutProvider';
import { Container, TextField, Typography } from '@mui/material';
import ActionButton from '../../../DesignSystem/atoms/ActionButton/ActionButton';
import { EstateContext } from '../../../../providers/EstateProvider';
import { DocumentContext } from '../../../../providers/DocumentProvider';

  type CreateFolderFormProps = {
    testId?: string
  }

const CreateFolderForm: FC<CreateFolderFormProps> = ({testId}) => {
    
  const { setDisplayModal, setModalContents } = useContext(LayoutContext)
  const { selectedEstateId } = useContext(EstateContext)
  const { refetchDocumentsList } = useContext(DocumentContext)
  const [ createFolderMutation ] = useCreateFolderMutation();
  const [ options, setOptions] = useState<any>({})

  const handleSubmit = async (
    values: CreateFolderInput,
    { resetForm }: { resetForm: any },
  ) => {
    createFolderMutation({
      variables: {
        data: {
          estateId: values.estateId,
          folderName: values.folderName,
        },
      },
      onCompleted: (data) => {
        setDisplayModal(false);
        refetchDocumentsList()
        resetForm();
      }, 
      onError: (e: ApolloError) => {
        console.error("Failed createFolderMutation call");
        console.error(e.message);
        resetForm();
        alert(e.message);
      },
    });
    
    customSubmitAction()
  }

  const initialValues = {
    estateId: selectedEstateId || "",
    folderName: "",
  };

  const validationSchema = Yup.object().shape({ 
    estateId: Yup.string().required("estateId is required"),
    folderName: Yup.string().required("folderName is required").matches(/^[a-zA-Z0-9_': -]*$/, 'Only alphanumeric characters plus _ - : \' and space are allowed'),  
  });

  const customSubmitAction = () => {
    // Optional: add additional actions to be executed on submit.
  }

  return (
    <div data-testid={testId} className='createFolderFormContainer'>
      <Typography variant='h4' className='createFolderFormHeader'>
        Create Folder Form
      </Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          errors, touched, submitCount, setFieldValue, 
        }) => (
          <Form noValidate>
            <Container className="formContainer">
              <Container className="formSection">

                <div className={"inputWrapper estateIdFieldWrapper textInputWrapper"} key={"estateId0"} data-testid={testId}>
                  <Field
                    className={"estateIdInput fieldInput textInput"}
                    required={true}
                    id={"estateId"}
                    label={"Estate Id"}
                    name={"estateId"}
                    type={"hidden"}
                    autoComplete={"estateId"}
                    value={selectedEstateId}
                    // as={TextField}
                    error={submitCount > 0 && touched.estateId && !!errors.estateId}
                  />
                  <ErrorMessage name={"estateId"} component="div" className="inputErrorMessage"/>
                </div>
      
                <div className={"inputWrapper folderNameFieldWrapper textInputWrapper"} key={"folderName1"} data-testid={testId}>
                  <Field
                    className={"folderNameInput fieldInput textInput"}
                    required={true}
                    id={"folderName"}
                    label={"Folder Name"}
                    name={"folderName"}
                    type={"text"}
                    autoComplete={"folderName"}
                    as={TextField}
                    error={submitCount > 0 && touched.folderName && !!errors.folderName}
                  />
                  <ErrorMessage name={"folderName"} component="div" className="inputErrorMessage"/>
                </div>
              </Container>
            </Container>

            <Container className="buttonsContainer">
              <ActionButton
                ariaLabel="Submit form"
                variant="outline"
                className="submitFormButton"
                handleClick={() => {
                  setDisplayModal(false);
                  setModalContents(null);
                }}
              >
                Cancel
              </ActionButton>

              <ActionButton
                type="submit"
                ariaLabel="Submit form"
                variant="solid"
                className="submitFormButton"
              >
                 Submit
              </ActionButton>
            </Container>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default CreateFolderForm