import { FC } from 'react'
import './BackNavHeader.scss'
import Typography from '../../DesignSystem/atoms/Typography/Typography';
import { ReactComponent as BackIcon } from '../../../assets/icons/navigation/arrow-left.svg'
import LinkButton from '../../DesignSystem/atoms/LinkButton/LinkButton';
import { useNavigate } from 'react-router-dom';

interface BackNavHeaderProps {
  testId?: string
  fixed?: boolean
  headerText?: string
}

const BackNavHeader: FC<BackNavHeaderProps> = ({
  testId, fixed=false, headerText,
}) => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1);
  }

  return (
    <div data-testid={testId} className={`backNavHeaderContainer ${fixed && 'fixed'}`}>
      <LinkButton variant={'borderless'} ariaLabel={'Back Link'} className='transparent backButton' handleClick={handleBackClick}>
        <BackIcon />
      </LinkButton>
      <Typography font="UI1" variant='P1' casing='uppercase'>
        {headerText}
      </Typography>
    </div>
  )
}

export default BackNavHeader
