import { FC, ReactNode, useContext, useEffect, useState } from "react"
import './Mobile.scss'
import { Card, Typography } from "@mui/material"
import LoadingOverlay from "../LoadingOverlay"
import { DocumentSign, FormFields, Manual } from "."
import { MobileLayout } from "../../components/Layouts"
import DocumentUpload from "./DocumentUpload/DocumentUpload"
import ComponentSubTypeIcon from "../../components/ComponentSubTypeIcon/ComponentSubTypeIcon"
import ActionButton from "../../components/DesignSystem/atoms/ActionButton/ActionButton"
import { ActionCardStatus, ActionCardType, useGetActionCardLazyQuery, useGetActionCardQuery, useGetUserActionCardLazyQuery, useStartActionCardMutation } from "../../types/graphql"
import { EstateContext } from "../../providers/EstateProvider"
import useStatus from "../../hooks/useStatus"
import ScheduleAppointment from "./ScheduleAppointment/ScheduleAppointment"
import { useParams } from "react-router-dom"
import { UserContext } from "../../providers/UserProvider"

const MobileView: FC<{ testId?: string }> = ({ testId }) => {
  const { selectedEstateId, currentEstate } = useContext(EstateContext)
  const { currentUser } = useContext(UserContext)
  const { actionCardId } = useParams()
  const [ body,           setBody ] = useState<ReactNode>(<LoadingOverlay />)
  const [ statusNotification, setStatusNotification ] = useState({
    title: '',
    message: '',
    visible: false,
    type: '',
  })
  const [ currentAction,  setCurrentAction ] = useState<any>(null)
  const [ description,    setDescription ] = useState('')
  const [ name,           setName ] = useState('')
  const [ status,         setStatus ] = useState<ActionCardStatus | undefined>(undefined)
  const { statusIcon, statusClass } = useStatus(status)


  const [getActionCard, {loading: actionLoading, refetch: actionRefetch}] = useGetUserActionCardLazyQuery({
    onError: (error) => {
      alert(error)
    },
  })

  const getActionCardData = async () => {
    if (!actionCardId) return;

    const actionData = await getActionCard({
      variables: {
        where: {id: { equals: actionCardId }},
        estateId: selectedEstateId,
      },
    })
    const returnedAction = actionData?.data?.getUserEstate?.actionCards[0];
    if (!returnedAction) return;
    
    setCurrentAction(returnedAction)

    // Set the action details body based on the action type
    switch (returnedAction?.type) {
    case ActionCardType.Manual:
      setBody(() => (<Manual currentAction={returnedAction} />));
      break
    case ActionCardType.FormFields:
      setBody(() => (<FormFields currentAction={returnedAction} refetchCurrentAction={actionRefetch} />));
      break
    case ActionCardType.DocumentSign:
      setBody(() => (<DocumentSign currentAction={returnedAction} />));
      break
    case ActionCardType.DocumentUpload:
      setBody(() => (<DocumentUpload currentAction={returnedAction} />));
      break
    case ActionCardType.ScheduleAppointment:
      setBody(() => (<ScheduleAppointment currentAction={returnedAction} />));
      break
    default:
      break
    }

    // Set the action details header based on the action status
    switch (returnedAction?.status) {
    case ActionCardStatus.InProgress:
      setStatusNotification({
        title: "You've done this.",
        message: "Alix will review everything for you, then mark it as complete.",
        visible:true,
        type:'inProgress',
      })
      break
    case ActionCardStatus.Completed:
      setStatusNotification({
        title: returnedAction?.completedAt ? `Marked complete on ${returnedAction?.completedAt}` : "Marked complete",
        message: "The Alix Care Team has confirmed this action as completed.",
        visible:true,
        type:'complete',
      })
      break
    default:
      break
    }

    /** =============================================================== **\
              DO NOT DELETE THE FOLLOWING 4 LINES UNLESS YOU WANT 
                    TO FACE THE WRATH OF THE ALIX TEAM
      The following lines are used to dynamically populate the
      variables in the description and name using the `eval` function, 
      and the description and name returned in the action card query.
      The string includes interpolation that is looking for the variables
      named:
        - deceasedFirstName
        - deceasedLastName
        - customerFirstName
        - customerLastName


                          SO..... DON'T DELETE THEM
    \** =============================================================== **/
    const deceasedFirstName = currentEstate?.getUserEstate?.deceased?.firstName
    const deceasedLastName = currentEstate?.getUserEstate?.deceased?.lastName
    const customerFirstName = currentUser?.getCurrentUser?.firstName
    const customerLastName = currentUser?.getCurrentUser?.lastName

    if (returnedAction?.description) setDescription(eval('`' + returnedAction?.description + '`'))
    if (returnedAction?.name) setName(eval('`' + returnedAction?.name + '`'))
    if (returnedAction?.status) setStatus(returnedAction.status)
  }

  useEffect(() => {
    if (!actionCardId) return;
    getActionCardData();
  }, [actionCardId])


  const [startActionCardMutation] = useStartActionCardMutation({
    onCompleted: (data) => {
      actionRefetch()
    },
    onError: (error) => {
      alert(error)
    },
  })
    
  const handleNotifyTeam = () => {
    if(currentAction?.id){
      startActionCardMutation({
        variables: {
          startActionCardId: currentAction?.id,
          estateId: selectedEstateId,
        },
      })
    }
  }

  return (
    // Establish the common elements of the action Details page that will wrap the body
    <MobileLayout
      includeBackNavHeader
      fixedHeader
      includeSubHeader={false}
      headerText="Action Details"
    >
      <div id="actionDetailsMobile" className="actionDetailsLayout" >
        <div className="actionDetailsContainer" data-testid={testId}>
          { statusNotification.visible && 
          <Card className={`actionDetailsStatusContainer ${statusClass}`}>
            {statusIcon}
            <div className="statusNotificationContent">
              <Typography variant='body1' className={`statusNotificationTitle ${statusClass}`}>
                {statusNotification.title}
              </Typography>
              <Typography variant='body1' className={`statusNotificationMessage ${statusClass}`}>
                {statusNotification.message}
              </Typography>
            </div>
          </Card>
          }

          <div className="actionDetailsHeader">
            <div className="actionDetailsTitle">
              {<ComponentSubTypeIcon subType={currentAction?.component?.subType || ''} />}
              <Typography variant='body1' className="actionDetailsActionName">
                {currentAction?.task?.name}
              </Typography>
              <Typography variant='h4' className="actionDetailsActionName">
                {name}
              </Typography>
            </div>
          </div>
          { currentAction?.description !== '<p><br></p>' &&
            currentAction?.description !== '' &&
            !currentAction?.description &&
        <div className="actionDetailsDivider"></div>}

          {/* Dynamic Body */}
          <div className="actionDetailsBody">
            <div className="actionContent">
              {  currentAction?.description !== '<p><br></p>' && 
                currentAction?.description !== '' &&
                currentAction?.description &&
                <>
                  <Typography variant='body1' className="actionDetailsBodyHeader">
                    A Note From Your Team
                  </Typography>
                  <Typography 
                    variant='body1'
                    className="actionDetailsDescription"
                    dangerouslySetInnerHTML={{ __html: description || '' }}
                  />
                </>
              }
              {body}
              { currentAction?.status === ActionCardStatus.New &&
                !currentAction.locked &&
                !currentAction.formFieldName &&
                <div className="actionDetailsButtonContainer">
                  <ActionButton 
                    variant="outline"
                    ariaLabel="notification button"
                    className="actionDetailsButton actionDetailsNotificationButton"
                    handleClick={() => handleNotifyTeam()}
                  >
                    <Typography variant="body1" className="notificationButtonText">
                      {"I'm done"}
                    </Typography>
                  </ActionButton>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </MobileLayout>
  )
}

export default MobileView
