import { FC, useContext, useEffect, useState } from 'react'
import './DocumentDownload.scss'
import { ActionContext } from '../../../providers/ActionProvider'
import { LayoutContext } from '../../../providers/LayoutProvider'
import DropZoneInput from '../../../components/File/DropZoneInput/DropZoneInput'
import { AccountContext } from '../../../providers/Authentication'
import { DocumentContext, IFile } from '../../../providers/DocumentProvider'
import UploadedFilesList from '../../../components/File/FilesList/FilesList'
import { useScreenWidth } from '../../../helpers'
import FilePickerInput from '../../../components/File/FilePickerInput/FilePickerInput'
import { EstateContext } from '../../../providers/EstateProvider'
import { ListDocumentsQuery, useListDocumentsQuery } from '../../../types/graphql'
import axios from 'axios';
import FilesList from '../../../components/File/FilesList/FilesList'
import ActionButton from '../../../components/DesignSystem/atoms/ActionButton/ActionButton'
import JSZip from 'jszip';
import { CircularProgress, Typography } from '@mui/material'
import { ReactComponent as ChevronUp } from '../../../assets/icons/navigation/chevron-up.svg'
import NumberCircle from '../../../components/NumberCircle/NumberCircle'

interface DocumentDownloadProps {
  testId?: string
  currentAction: any
}

const fetchFile = async (url: string) => {
  try {
    const response = await axios.get(url, { responseType: 'blob' });
    const blob = await response.data;

    // Extract the name from the URL
    const urlParts = url.split('/');
    const name = urlParts[urlParts.length - 1].split('?')[0];

    const file = new File([blob], name, { type: blob.type });
    return file;
  } catch (error) {
    console.error(error);
  }
}

const downloadAllFiles = async (files: IFile[], name: string) => {
  const zip = new JSZip();
 
  await Promise.all(files.map(async (file, index) => {
    const uploadedFile = await fetchFile(file.signedUrl || '');
    if (uploadedFile) {
      zip.file(`${index + 1}-${decodeURIComponent(uploadedFile.name)}`, uploadedFile);
    }
  }))

  const content = await zip.generateAsync({type:"blob"});
  const url = URL.createObjectURL(content);
  const link = document.createElement('a');
  link.href = url;
  link.download = `${name}.zip`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

const DocumentDownload: FC<DocumentDownloadProps> = ({testId, currentAction}) => {
  // const {currentAction} = useContext(ActionContext)
  const {hasAdminAuthorization} = useContext(AccountContext)
  const {
    folderFiles, setFolderFiles, setRefetchDocumentsList,
  } = useContext(DocumentContext);
  const {selectedEstateId} = useContext(EstateContext);
  const screenWith = useScreenWidth()
  const [showFileList, setShowFileList] = useState<boolean>(false)

  const [ filePath, setFilePath ] = useState<string>('')

  const {
    data, error, refetch: refetchDocuments, 
  } = useListDocumentsQuery({
    variables: {
      estateId: selectedEstateId, // value for 'estateId'
      directory: `${currentAction?.documentDownloadUrl}/`,// value for 'directory'
    },
  });

  const onQueryComplete = async (data: ListDocumentsQuery) => {
    const formattedFiles: Record<string, IFile> = {}
    await Promise.all(data.listDocuments.map(async (document) => {
      const file = await fetchFile(document?.signedUrl || '');
      if(!file) return;
      formattedFiles[document?.name || ''] = {
        uploadedFile: file,
        id: document?.id || '',
        name: document?.name || '',
        uploadSuccess: true,
        uploadProgress: 100,
        uploadError: '',
      }
    }));
    // check if formattedFiles is empty
    console.log("formattedFiles", formattedFiles)
    if(Object.keys(formattedFiles).length === 0){ 
      setFolderFiles(null)
      return
    }
    setFolderFiles((files) => ({
      ...files,
      [currentAction?.documentDownloadUrl || '']: formattedFiles,
    }))
  }

  useEffect(() => {
    setFolderFiles(null)
  },[])

  useEffect(() => {
    // Insure Path Prefix is in the correct format
    setFilePath( `${currentAction?.documentUploadUrl}/` || '')
  }, [currentAction])

  useEffect(() => {
    if( data ) {
      onQueryComplete(data);
    }

    if( error ) {
      console.error(error);
      alert(error);
    }

    setRefetchDocumentsList(() => refetchDocuments)
  }, [data, error])

  return (
    <div data-testid={testId} className='documentDownloadContainer'>
      <ActionButton 
        className='fileDropdownHeader' 
        variant='solid' 
        ariaLabel={'show hide file list'}
        handleClick={() => setShowFileList(!showFileList)}
      >
        <div className='leftHeader'>
          <Typography variant='body1' className='dark'>Attached Files</Typography>
          { folderFiles && currentAction?.documentDownloadUrl && folderFiles[currentAction?.documentDownloadUrl] ?
            <NumberCircle number={Object.keys(folderFiles[currentAction?.documentDownloadUrl] || {}).length}/>
            :
            <CircularProgress className='fileListSpinner' size={20}/>}
        </div>
        <ChevronUp className={`fileChevron ${showFileList && 'show'}`} />
      </ActionButton>

      <div className={`fileListContainer fileDropdownDrawer ${showFileList && 'show'}`}>
        {
          folderFiles && folderFiles[currentAction?.documentDownloadUrl || ""] &&
          <FilesList directory={currentAction?.documentDownloadUrl || ""} type="download"/>
        }
      </div>

      <hr />

      <div className='fileDownloaderContainer'>
        <ActionButton 
          ariaLabel='Download All Files'
          variant='solid'
          className='dark downloadAllButton'
          handleClick={() => {
            if(!folderFiles || (folderFiles && !folderFiles[currentAction?.documentDownloadUrl || ""]) ) return;
            const files = Object.values(folderFiles[currentAction?.documentDownloadUrl || ""]);
            downloadAllFiles(files, currentAction?.documentDownloadUrl || 'files');
          }}
        >
          Download Everything I Need
        </ActionButton>
      </div>
    </div>
  )
}

export default DocumentDownload