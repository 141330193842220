import { FC, useContext, useEffect, useRef, useState } from 'react';

import { Container } from '@mui/material';
import EstatePieChart from '../../components/Charts/EstatePieChart/EstatePieChart';
import { useGetUserEstateQuery, useGetEstateDistributionBreakdownQuery, useUpdateOneEstateMutation } from '../../types/graphql';
import { EstateContext } from '../../providers/EstateProvider';
import { useScreenWidth } from '../../helpers';
import EstimatedDistribuitionCard from '../../components/EstimatedDistribuitionCard/EstimatedDistribuitionCard';
import { useDecision } from '@optimizely/react-sdk';
import { ReactComponent as ChevronLeftIcon } from '../../assets/icons/navigation/chevron-left.svg'
import { ReactComponent as ChevronRightIcon } from '../../assets/icons/navigation/chevron-right.svg'

import './estateValueAndChart.scss'

interface EstateValueAndChartProps {
  testId: string
}

const EstateValueAndChart: FC<EstateValueAndChartProps> = ({ testId }) => {
  const screenWidth = useScreenWidth()
  const {
    currentEstate, selectedEstateId, refetchCurrentEstate,
  } = useContext(EstateContext)

  const [allowHorizontalScrollInPieChart] = useDecision('alllow_horizontal_scroll_feature', { autoUpdate: true });
  const { data: estateDistributionBreakdownData } = useGetEstateDistributionBreakdownQuery({ variables: { estateId: currentEstate?.getUserEstate?.id ?? "" } });

  const [currentIndex, setCurrentIndex] = useState(0);
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const handleScroll = () => {
    if (scrollContainerRef.current) {
      const scrollLeft = scrollContainerRef.current.scrollLeft;
      const width = scrollContainerRef.current.offsetWidth;
      const newIndex = Math.round(scrollLeft / width);
      setCurrentIndex(newIndex);
    }
  };

  const scrollTo = (direction: 'left' | 'right') => {
    if (scrollContainerRef.current) {
      const scrollAmount = direction === 'left' ? -scrollContainerRef.current.clientWidth : scrollContainerRef.current.clientWidth;
      scrollContainerRef.current.scrollBy({
        left: scrollAmount,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.addEventListener('scroll', handleScroll);
      return () => {
        if (scrollContainerRef.current) {
          scrollContainerRef.current.removeEventListener('scroll', handleScroll);
        }
      };
    }
  }, []);

  const { data: estateData } = useGetUserEstateQuery({
    variables: { estateId: selectedEstateId  },
    onError: (error) => {
      console.error('Error in EstateValueAndChart', error)
    },
  })

  const handleDisplay = () => { document.body.style.overflow = 'auto'; }

  useEffect(() => {
    handleDisplay()
  }, [currentEstate])

  // Display the estate value and the Pie Chart
  return (
    <div className="estateValueOuterContainer" data-testid={testId}>
      <Container className="estateValueContainer" data-testid={testId}>
        <div className='arangeDisplay'>
          {allowHorizontalScrollInPieChart.enabled && (screenWidth > 800 && <ChevronLeftIcon onClick={() => scrollTo('left')} />)}

          {/* Display the EstatePieChart */}
          {!allowHorizontalScrollInPieChart.enabled && (
            <div className="chartContainer">
              <EstatePieChart
                sideLength={screenWidth < 800 ? 240 : 300}
                innerRadius={screenWidth < 800 ? 90 : 120}
                defaultColorFill="#000"
                testId="estate-view-piechart"
              />
            </div>
          )}

          {allowHorizontalScrollInPieChart.enabled && (
            <>
              <div className='standardScrollDivider'>
                <div
                  ref={scrollContainerRef}
                  className='scrollContainer'
                >
                  <div
                    className='scrollContainerInternalCenter'
                  >
                    <div className="chartContainer">
                      <EstatePieChart
                        sideLength={screenWidth < 800 ? 240 : 300}
                        innerRadius={screenWidth < 800 ? 90 : 120}
                        defaultColorFill="#000"
                        testId="estate-view-piechart"
                      />
                    </div>
                  </div>
                  <div
                    className='scrollContainerInternalStart'
                  >
                    <EstimatedDistribuitionCard
                      totalValue={estateDistributionBreakdownData?.estateDistributionBreakdown?.totalValue}
                      debts={estateDistributionBreakdownData?.estateDistributionBreakdown?.debts}
                      alixFee={estateDistributionBreakdownData?.estateDistributionBreakdown?.alixFee}
                      estimatedDistribution={estateDistributionBreakdownData?.estateDistributionBreakdown?.estimatedDistribution}
                      distributionInfo={estateDistributionBreakdownData?.estateDistributionBreakdown?.distributionInfo}
                      feeCalculationInfo={estateDistributionBreakdownData?.estateDistributionBreakdown?.feeCalculationInfo}
                    />
                  </div>
                </div>
                <div className='arangeDisplayTop'>
                  <div
                    className='scrollIcons'
                    style={{ backgroundColor: currentIndex === 0 ? '#000' : '#ccc' }} />
                  <div
                    className='scrollIcons'
                    style={{ backgroundColor: currentIndex === 1 ? '#000' : '#ccc' }} />
                </div>
              </div>
            </>
          )}
          {allowHorizontalScrollInPieChart.enabled && (screenWidth > 800 && <ChevronRightIcon onClick={() => scrollTo('right')} />)}
        </div>
      </Container>
    </div>
  )
}

export default EstateValueAndChart
