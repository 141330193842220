import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';


const useQueryParams = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const stringParams = queryParams.toString();
  const hasParams = Boolean(stringParams)
  const paramsArray = stringParams.split('&');

  const params = paramsArray.reduce((acc: { [key: string]: string }, curr: any) => {
    const [key, value] = curr.split('=');
    acc[key] = decodeURIComponent(value);
    return acc;
  }
  , {} );


  return {
    params,
    hasParams,
  }
}



export default useQueryParams;